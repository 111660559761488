export class ManejoItemsIncompletos {


    IdItemsPedido:number[];
    IdMotivo :number;
    IdCentroProduccion :number;
    IdConceptoCancelacion :number;
    IdUsuario :number;
    ConsecutivoItem: number;
    
}

