import { Variedad } from 'modelos/Variedad';
import { Grado } from 'modelos/Grado';
import { Color } from 'modelos/Color';
import { Especie } from 'modelos/Especie';

export class DesgloseRecetaItem {

  IdProducto: number;
  IdReferencia: number;
  IdRecetaItemPedido: number;
  IdColor: number;
  IdGrado: number;
  IdEspecie: number;
  IdVariedad: number;
  Observaciones: string;
  Cantidad: number;
  NombreProducto: string;
  CreatedBy: string;
  CreatedDate: Date;
  UpdatedDate: Date;
  UpdatedBy: string;
  IdDesgloseRecetaItem: number;//siempre 0
  ValorDesgloseFacturacion: number;//siempre 0
  Porcentaje: number;//siempre 0
  esFlorSecundaria: boolean;//siempre falso
  NumeroRamosReceta: string;
  
  
  
  CodigoReceta: string;
  IdDesgloseRecetaItemOrdenFija: number;
  IdCategoriaVariedad: number;
  NumeroReceta: string;

  
  Variedad: Variedad;
  Especie: Especie;
  Grado: Grado;
  Color: Color;
}