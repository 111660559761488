import { EmpresaTransporte } from "modelos/CRM/EmpresaTransporte";
import { AgenciaCarga } from "modelos/Admin/AgenciaCarga";
import { ItemPedido } from './ItemPedido';

export class Guia {
  IdGuia: number;
  FechaSalida: Date;
  IdEmpresaTransporte: number;
  IdAgenciaSIA: number;
  IdEmpresaTransporteEntrega: number;
  IdAgenciaCarga: number;
  GuiaMaster: string;
  CreatedBy: string;
  CreatedDate: Date;
  UpdatedBy: string;
  UpdatedDate: Date;
  Vuelo: string;
  //ItemsPedido:ItemPedido[];
  EmpresaTransporte: EmpresaTransporte;
  AgenciaCarga: AgenciaCarga;
  IdTipoCarga: number;
  IdDestinoA:number;
  NombreDestino:number;
  
}
