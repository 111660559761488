import { Color } from 'modelos/Color';
import { Especie } from 'modelos/Especie';
import { CategoriaVariedad } from 'modelos/CategoriaVariedad';

export class Variedad {
  CreatedBy: string;
  CreatedDate: Date;
  UpdatedBy: string;
  UpdatedDate: Date;
  IdVariedad: number;
  Nombre: string;
  NombreIngles: string;
  IdEspecie: number;
  IdColor: number;
  Estado: boolean;
  Imagen: string;
  IdEstadoProduccion: number;
  IdCategoriaVariedad: number;
  EsSusceptible: boolean;
  Version: string;
  CategoriaVariedad: CategoriaVariedad[];
  Color: Color;
  Especie: Especie;
  Procedencia : string;
  Ensayo: boolean;
  CicloVida: number;
  CicloProduccion: number;
  Caracteristicas: string;
  SemPicoSiembra: number;
}
