import { ProductoItemDesgloce } from 'modelos/ProductoItemDesgloce';


export class  CatalogoPieza {
    IdProductoItem :number;
    IdEmpaque :number;
    Codigo :string;
    Activo :boolean;
    Descripcion :string;
    Observaciones :string;
    IdCategoria  :number;
    IdTipoEmpaque  :number;
    DesLarga:string;
    LlaveSHA1 :string;
    Composicion :string;
    Createdby :string;
    Createddate:Date;
    Updatedby:string;
    Updateddate:Date;
    ProductosItemDesgloce: ProductoItemDesgloce[];
    } 