import { TipoCargoCliente } from 'modelos/TipoCargoCliente';
import { AplicacionCargoCliente } from 'modelos/AplicacionCargoCliente';
import { Cliente } from 'modelos/Cliente';
import { Empaque } from 'modelos/Empaque';
import { CargoEconomico } from 'modelos/CargoEconomico';


export class CargoCliente {
  AplicacionCargoCliente: AplicacionCargoCliente;
  Cliente: Cliente;
  TipoCargosCliente: TipoCargoCliente ;
  CargoEconomico: CargoEconomico;
  CreatedBy: string;
  CreatedDate: Date;
  UpdatedBy: string;
  UpdatedDate: Date;
  IdCargosCliente: number;
  IdCargoEconomico: number;
  IdAplicacionCargoCliente: number;
  Valor: number;
  IdEmpaque: number;
  IdCliente: number;
  IdTipoCargosCliente: number;
  Empaque:Empaque;
}
