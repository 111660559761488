import { Transportecp } from "modelos/logistica/Transportecp";

export class Filtro {
    
    IdTipoEmpaque: number;
    IdPedido: number;
    IdEmpaque: number;
    IdEspecie: number;
    NombreEspecie: string;
    IdColor: number;
    NombreColor: string;
    IdGrado: number;
    NombreGrado: string;
    IdVariedad: number;
    NombreVariedad: string;
    IdTipoProducotReferencia: number;
    IdCategoria: number;
    IdInsumo: number;
    Mixto: number;
    IdReferencia: number;
    IdTipoProducto: number;
    LlaveSHA1: string;
    IdProducto: number;
    Descripcion: string;
    FechaInicio: Date;
    FechaFin: Date;
    Horario: string;
    TransporteCP: Transportecp;
    FechaPedido: Date;
    IdGuiaMaster:number;
    GuiaMaster:string;
    IdGuiaHija:number;
    IdTercero:number;
    HoraEntrega:string;
    Codigocp:string;
    Estado:string;
    IdItemPedido:number;
    IdTransporteCP:number;
    Identificacion:string;
    Usuario:string;
    Pass:string;
    Ip:string;
    IdPais:number;
    IdDestinatario:number;
    FechaDespacho:Date;
    IdEmpresa:number;
    IdConstancia:string;
    IdCentroProduccion:number;
    IdCliente:number;
    TipoOrden:string;
    IdUsuario:number;
    ConsecutivoPedido:number;
    Observaciones:string;
    IdConcepto: number;
    Pieza:number;
    NumeroOrdenCliente:string;
    IdEstadoFlujoTrabajo:number;
    ConsecutivoItem:number;
    EstadoPrecios: boolean;
    AsignarGuia:boolean;
    Activo:boolean;
    itemPedido:number[];
    Propiedades:string[];
    ValoresAnteriores:string[];
    ValoresNuevos:string[];
    Todos:boolean;

}
