import { ItemPedido } from './ItemPedido';
import { Especie } from 'modelos/Especie';
import { MatTableDataSource } from '@angular/material';

export class Pedido {
    IdPedido: number;
    ConsecutivoPedido: number;
    NombreTipoPedido: string;
    IdCliente: number;
    NombreCliente:string;
    IdSubCliente: number;
    NombreSubcliente: string;
    IdEmpresa: number;
    NombreEmpresa: string;
    FechaPedido: Date;
    IdMarca: number;
    Marca: string;
    IdTemporada: number;
    Temporada: string;
    IdUsuario: number;
    Usuario: string;
    NumeroOrdenCliente: string;
    IdPlazoPago: number;
    PlazoPago: string;
    CajasFull: number;
    PiezasPedidas: number;
    PiezasConfirmadas: number;
    Tallos: number;
    ValorTotal: number;
    CreatedBy: string;
    CreatedDate: Date;
    UpdatedBy: string;
    UpdatedDate: Date;
    Observaciones:string;
    HoraPedido: Date;
    IdTipoPedido: number;
    IdEstadoFlujoTrabajo: number;
    TotalItems:number;
    Items:ItemPedido[];
    ItemPedido:ItemPedido[];
    TablaItem: MatTableDataSource<any>;
    Especies:Especie[];
    TieneErrores:boolean;
    opcionUsuario:number;
    seleccionado:boolean=false;
    indeterminado: boolean=false;
    numeroPedido:number;
    EstadoRevision:string;
}
