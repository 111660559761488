import { CentroProduccion } from 'modelos/CentroProduccion';
import { Empresa } from 'modelos/Empresa';
import { Guia } from 'modelos/mercadeo/Guia';
import { GuiaHija } from 'modelos/mercadeo/GuiaHija';
import { Empaque } from 'modelos/Empaque';
import { MarcacionCliente } from 'modelos/MarcacionCliente';
import { TipoEmpaque } from 'modelos/TipoEmpaque';
import { UnidadFacturacion } from 'modelos/UnidadFacturacion';
import { EstadoFlujoTrabajo } from 'modelos/Admin/EstadoFlujoTrabajo';
import { DistribuidorCliente } from 'modelos/CRM/DistribuidorCliente';
import { Marca } from 'modelos/Admin/Marca';
import { RecetaItemPedido } from 'modelos/mercadeo/RecetaItemPedido';
import { ManejoItemsIncompletos } from './ManejoItemsIncompletos';
import { Especie } from 'modelos/Especie';
import { ItemPedidoRevisionPrecios } from './RevisionPrecioItem';
import { Pedido } from './Pedido';

export class ItemPedido {
  IdItemPedido: number;
  IdPedido: number;
  Codigo: string;
  Descripcion: string;
  OrdenCompra: string;
  Marcacion: string;
  FechaSalida: Date;
  Modificacion: string;
  PiezasPedidas: number;
  PiezasConfirmadas: number;
  NombreCentroProduccion: string;
  IdUnidadFacturacion: number;
  IdTipoEmpaque: number;
  IdCentroProduccion: number;
  IdEmpaque: number;
  IdEstadoFlujoTrabajo: number;
  Unidades: number;
  ValorUnitario: number;
  EsMuestra: boolean;
  Observaciones: string;
  CreatedBy: string;
  CreatedDate: Date;
  UpdatedBy: string;
  UpdatedDate: Date;
  IdMarca: number;
  Tallos: number;
  IdDistribuidor: number;
  IdEmpresa: number;
  IdMarcacion: number;
  IdReferencia: number;
  IdReferenciaInicial: number;
  ValorTotal: number;
  tblMarca: Marca;
  Precio: number;
  IdGuia: number;
  IdGuiaHija: number;
  IdConceptoAnulacion: number;
  IdConceptoCancelacion: number;
  IdItemOrdenFija: number;
  Consecutivo: number;
  EstadoGeneracionArchivo: boolean;
  IdPlantillaItem: number;
  FechaSalidaCmp: Date;
  Ramos: number;
  CajasFull: number;
  CentroProduccion: CentroProduccion;
  Empresa: Empresa;
  Guia: Guia;
  GuiaHija: GuiaHija;
  Empaque: Empaque;
  tblMarcacion: MarcacionCliente;
  TipoEmpaque: TipoEmpaque;
  UnidadFacturacion: UnidadFacturacion;
  EstadoFlujoTrabajo: EstadoFlujoTrabajo;
  DistribuidorCliente: DistribuidorCliente;
  Marca: Marca;
  RecetaItemPedido: RecetaItemPedido[];
  TallosIniciales: number;
  RamosIniciales: number;
  ItemPedidoRevisionPrecios: ItemPedidoRevisionPrecios[];
  ManejoItemsIncompletos: ManejoItemsIncompletos;
  ErrorEquivalencia: boolean;
  DescripcionExcel: string;
  Composicion: string;
  Especie: Especie;
  Pack: string;
  seleccionado: boolean = false;//si cambia tabla de segundo paso quitar
  Pedido: Pedido;
  ValorUnitarioInicial: number;
  IdUnidadFacturacionInicial: number;
}
