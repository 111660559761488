import { DesgloseRecetaItem } from 'modelos/mercadeo/DesgloseRecetaItem';
import { MatTableDataSource } from '@angular/material';

export class RecetaItemPedido {
        IdRecetaItemPedido:number;
        NumeroReceta:number;
        CodigoReceta:string;
        Descripcion:string;
        NumeroRamos:number;
        DateCode:string; 
        UPC:string;
        PrecioDetallista: number;
        NumeroEmpaqueProducto: string;
        IdItemPedido:number;
        ValorFacturacionReceta:number;
        CreatedBy:string;
        CreatedDate: Date;
        UpdatedBy:string;
        UpdatedDate:Date;
        IdRecetaItemOrdenFija:number;
        EstadoGeneracionArchivo:boolean;
        IdReferencia:number;
        UpcProductName: string;
        UpcGrowerCode: string;
        UpcObservations: string;
        DesgloseRecetaItem:DesgloseRecetaItem[];
        Flores:DesgloseRecetaItem[];
        Insumos:DesgloseRecetaItem[];
        tablaFlores: MatTableDataSource<any>;
        tablaInsumos: MatTableDataSource<any>;
}