export class ItemPedidoRevisionPrecios {
    IdItemPedidoRevisionprecio: number;
    IdItemPedido: number;
    IdPedido: number;
    Estado: string;
    Observaciones: string;
    CreatedBy: string;
    CreatedDate: Date;
    UpdatedBy: string;
    UpdatedDate: Date;
    Color: string;
}