export class RevisionPrecioPedido {
    CreatedBy: string;
    CreatedDate: Date;
    UpdatedBy: string;
    UpdatedDate: Date;
    IdPedido: number;
    IdCliente: number;
    ConsecutivoPedido: number;
    FechaPedido: Date;
    Observaciones: string;
    Estado: string;
    NombreCliente: string;
    NombreSubcliente: string;
    IdRegistro:number;
}