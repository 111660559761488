import { Empresa } from 'modelos/Empresa';
import { Usuario } from 'modelos/Usuario';
import { Ciudad } from 'modelos/ciudad';

export class CentroProduccion {
  CreatedBy: string;
  CreatedDate: Date;
  UpdatedBy: string;
  UpdatedDate: Date;
  IdCentroProduccion: number;
  IdTercero: number;
  Nombre: string;
  Direccion: string;
  Telefono: string;
  OtroTelefono: string;
  IdEmpresa: number;
  IdCiudad: number;
  Codigo: string;
  NombrePredio: string;
  Version: string;
  Empresa: Empresa;
  Usuario: Usuario;
  Ciudad: Ciudad;

}
