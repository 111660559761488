export class CatalogoProducto {
    IdProducto :number;
    IdEspecie :number;
    IdVariedad :number;
    IdGrado :number;
    IdColor :number;
    Activo :boolean;
    Descripcion:string;
    IdCategoria :number;
    IdTipoProducto :number;
    CreatedBy :string;
    CreatedDate :Date;
    UpdatedBy :string;
    UpdatedDate :Date;
}